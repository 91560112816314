.studentForm, .editStudentContainer {
  form {
    border: 1px solid $colorOne;
    margin: 20px 0;
    padding: 20px;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 300;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .mainForm {
    display: flex;
  }

  .upperForm {
    width: calc(70%);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .lowerForm {
    width: calc(30% - 20px);
  }

  input[type="text"] {
    width: calc(50% - 20px);
    margin: 10px;
    background-color: $background;
    border: none;
    border-bottom: 1px solid $colorOne;
  }

  textarea {
    background-color: rgba(0, 0, 0, 0);
    padding: 10px;
    margin: 10px;
  }

  input[type=submit] {
    margin: 10px 10px 0 10px;
    border: none;
    width: 120px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $primaryFont;
  }
}
