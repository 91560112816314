// Animations are in css. For animations in js, add class to trigger css animation.

.landingNav {
  position: relative;
}

.topBarContainer {
  position: fixed;
  left: 0;
  right: 0;
  height: 100px;
  top: -1px;
  z-index: 20;
  background-color: $background;
  border-bottom: 1px solid $colorOne;
}

// add height refers to the topbarContainer (background + line) and the border bottom
// .addHeight {
//   animation: addHeight 0.3s linear;
//   height: 86px;
// }

// .removeHeight {
//   animation: removeHeight 0.3s linear;
// }

// @keyframes addHeight {
//   from {
//     height: 0px;
//   }
//   to {
//     height: 86px;
//   }
// }

// @keyframes removeHeight {
//   from {
//     height: 86px;
//   }
//   to {
//     height: 0px;
//   }
// }

// .moveUp {
//   animation: moveUp 0.3s linear forwards;
// }

// .moveDown {
//   animation: moveDown 0.3s linear forwards;
// }

// @keyframes moveUp {
//   from {
//     transform: translateY(0);
//   }
//   to {
//     transform: translateY(-100px);
//   }
// }

// @keyframes moveDown {
//   from {
//     transform: translateY(-100px);
//   }
//   to {
//     transform: translateY(0);
//   }
// }

.navCircle {
  display: none;
  height: 10vw;
  width: 10vw;
  background-color: white;
  position: fixed;
  top: -10vw;
  right: -10vw;
  // z-index: -1;
  border-radius: 50%;
}

.topBar {
  position: fixed;
  // z-index: 100;
  left: 10%;
  right: 10%;
  top: 15px;
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// .logoBoxContainer, .mainNav {
//   flex: 1 0 50%;
// }

.mainNav {
  // width: 100%;
  display: flex;
  justify-content: flex-end;

  ul {
    display: flex;
  }

  li {
    text-align: center;
    font-size: 0.7rem;
    margin-left: 2vw;

    .contactLink,
    .logIn {
      display: block;
      text-align: center;
      font-size: 0.75rem;
      padding: 15px 20px;
      border-radius: 30px;
    }

    .contactLink {
      &:hover .animatedBorder::after {
        animation: borderExpand 0.4s ease-out forwards;
      }
    }

    .animatedBorder {
      display: block;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $colorOne;
      }
    }

    @keyframes borderExpand {
      from {
        width: 0%;
      }
      to {
        width: 100%;
      }
    }

    .logIn {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      background-color: $colorOne;
      color: $background;

      &:hover {
        background-color: $colorTwo;
        color: $colorOne;
      }
    }

    .movingText1 {
      display: inline-block;
      white-space: nowrap;
    }
  }
}

.miniNav {
  display: none;
}

.logoBoxContainer, .hamburger {
  position: relative;
  z-index: 10;
}

.hamburger {
  cursor: pointer;

  background: none;
  margin: 0;
  padding: 0;
  border: none;
  width: auto;
  border-radius: 0;

  .topBun,
  .bottomBun {
    height: 3px;
    width: 25px;
    background-color: $colorOne;
  }

  .topBun {
    margin-bottom: 8px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
}

.hamburgerLinks {
  display: none;
  opacity: 0;
  text-align: center;
  position: absolute;
  top: 40vh;
  left: 0;
  right: 0;
  // z-index: 20;

  a {
    font-size: 2rem;
    text-transform: none;
  }

  li {
    margin-bottom: 5vh;
  }
}

.hamburgerContactLink,
.hamburgerLogIn {
  &:hover {
    color: $colorTwo;
  }
}

.fadeIn {
  animation: fadeIn 0.75s linear forwards;
}

.fadeOut {
  animation: fadeOut 0.375s linear forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.open .topBun {
  animation: topBunOpen 0.5s linear forwards;
}

.open .bottomBun {
  animation: bottomBunOpen 0.5s linear forwards;
}

.close .topBun {
  animation: topBunClose 0.5s linear forwards;
}

.close .bottomBun {
  animation: bottomBunClose 0.5s linear forwards;
}

.expand {
  animation: expand 0.3s linear forwards;
}

.contract {
  animation: contract 0.75s linear forwards;
}

@keyframes expand {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(60);
  }
}

@keyframes contract {
  0% {
    transform: scale(60);
  }
  50% {
    transform: scale(60);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes topBunOpen {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(5.5px) rotate(0deg);
  }
  100% {
    transform: translateY(5.5px) rotate(45deg);
  }
}

@keyframes bottomBunOpen {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-5.5px) rotate(0deg);
  }
  100% {
    transform: translateY(-5.5px) rotate(-45deg);
  }
}

@keyframes topBunClose {
  0% {
    transform: translateY(5.5px) rotate(45deg);
  }
  50% {
    transform: translateY(5.5px) rotate(0deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
}

@keyframes bottomBunClose {
  0% {
    transform: translateY(-5.5px) rotate(-45deg);
  }
  50% {
    transform: translateY(-5.5px) rotate(0deg);
  }
  to {
    transform: translateY(0px) rotate(0deg);
  }
}

@media (max-width: 500px) {
  .sideNav {
    display: none;
  }

  .topBarContainer {
    height: 80px;
  }

  .topBar {
    top: 3vh;
    left: 8%;
    right: 8%;
  }

  .mainNav {
    display: none;
  }

  .miniNav,
  .navCircle {
    display: block;
  }

  // .addHeight {
  //   height: 67px;
  // }

  // @keyframes addHeight {
  //   from {
  //     height: 0px;
  //   }
  //   to {
  //     height: 67px;
  //   }
  // }

  // @keyframes removeHeight {
  //   from {
  //     height: 67px;
  //   }
  //   to {
  //     height: 0px;
  //   }
  // }
}
