a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:after, blockquote:before, q:after, q:before {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

audio, canvas, video {
  display: inline-block;
  max-width: 100%; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button, html, input, select, textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: .2em 0;
  background: #ccc;
  color: #000;
  padding: .2em 0; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: '';
  clear: both;
  height: 0; }

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.visuallyHidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0);
  overflow: hidden; }

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  cursor: url(../Assets/Cursors/cursorBlue20.png) 10 10, auto; }

button,
input[type="submit"],
a {
  cursor: url(../Assets/Cursors/cursorBlue20.png) 10 10, auto; }

html {
  font-size: 125%;
  background-color: white;
  color: #0000cc; }

h1::selection,
h2::selection,
h3::selection,
h4::selection,
p::selection,
a::selection,
li::selection,
span::selection,
em::selection {
  background-color: #ff99cc; }

body,
h1,
h2,
button,
p,
a,
img {
  margin: 0;
  padding: 0; }

a {
  text-decoration: none; }
  a:visited {
    color: #0000cc; }

.wrapper {
  width: 80%;
  max-width: 1090px;
  margin: 0 auto; }

img {
  width: 100%; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

button,
input[type="submit"] {
  margin-top: 40px;
  background-color: #0000cc;
  text-align: center;
  font-size: 0.75rem;
  padding: 12px 20px;
  border-radius: 30px;
  width: 160px;
  color: white; }

.App {
  min-width: 320px;
  overflow-x: hidden; }

.cursorHover {
  display: none; }

.cursorHover,
.cursorNormal {
  width: 80px; }

.cursorDownContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  width: 240px;
  height: 83px;
  left: calc(50% - 120px);
  bottom: 7vh;
  animation: fadeIn 1.5s linear forwards; }
  .cursorDownContainer:hover .cursorNormal {
    display: none; }
  .cursorDownContainer:hover .cursorHover {
    display: block;
    animation: arrowDown 1s ease-in-out infinite; }

@keyframes appear {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes arrowDown {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(15px); } }

@media (max-width: 500px) {
  .wrapper {
    width: 84%; } }

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../Assets/Fonts/IbmPlexSans/IBMPlexSans.woff2") format("woff2"), url("../Assets/Fonts/IbmPlexSans/IBMPlexSans.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../Assets/Fonts/IbmPlexSans/IBMPlexSans-Medium.woff2") format("woff2"), url("../Assets/Fonts/IbmPlexSans/IBMPlexSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../Assets/Fonts/IbmPlexSans/IBMPlexSans-Light.woff2") format("woff2"), url("../Assets/Fonts/IbmPlexSans/IBMPlexSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Avenir";
  src: url("../Assets/Fonts/Avenir/Avenir-Medium.woff2") format("woff2"), url("../Assets/Fonts/Avenir/Avenir-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Avenir";
  src: url("../Assets/Fonts/Avenir/Avenir-Black.woff2") format("woff2"), url("../Assets/Fonts/Avenir/Avenir-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Avenir";
  src: url("../Assets/Fonts/Avenir/Avenir-Heavy.woff2") format("woff2"), url("../Assets/Fonts/Avenir/Avenir-Heavy.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Avenir";
  src: url("../Assets/Fonts/Avenir/Avenir-Book.woff2") format("woff2"), url("../Assets/Fonts/Avenir/Avenir-Book.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

html {
  font-family: "IBM Plex Sans", serif; }

li {
  line-height: 1; }

p {
  font-size: 2.1rem;
  line-height: 1.6; }

.under {
  text-decoration: underline; }

.bold {
  font-weight: bold; }

h1,
h2,
h3,
h4 {
  font-family: "Avenir", serif;
  font-weight: 1000; }

aside h4,
aside p {
  font-family: "IBM Plex Sans", serif; }

h1 {
  text-align: center;
  position: relative;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.1; }

h2 {
  text-align: left;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1; }

.howItWorks {
  position: relative; }

h3 {
  font-weight: 500;
  font-size: 1.5rem; }

.sectionThree .stepLeft span {
  font-family: "Avenir", serif; }

.fa-arrow-up {
  font-size: 0.75rem;
  transform: rotate(180deg);
  margin-left: 6px; }

.hamburgerLinks a {
  font-family: "Avenir", serif; }

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
  opacity: 1;
  color: #0000cc;
  font-family: "IBM Plex Sans", serif;
  font-weight: 500; }
  input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  input[type="number"]::placeholder,
  input[type="password"]::placeholder {
    font-weight: 500;
    font-family: "IBM Plex Sans", serif;
    color: #0000cc;
    opacity: 0.7; }

textarea {
  opacity: 1;
  font-family: "IBM Plex Sans", serif;
  font-weight: 500;
  color: #0000cc; }
  textarea::placeholder {
    opacity: 0.7;
    font-weight: 500;
    font-family: "IBM Plex Sans", serif;
    color: #0000cc; }

a p {
  font-size: 0.7em;
  font-weight: 500;
  text-decoration: underline;
  text-transform: none; }

a,
button,
.logInSubmit {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  border: none;
  font-family: "IBM Plex Sans", serif; }

button,
.logInSubmit {
  border: none; }
  button:hover,
  .logInSubmit:hover {
    background-color: #ff99cc;
    color: #0000cc; }

.landingNav {
  position: relative; }

.topBarContainer {
  position: fixed;
  left: 0;
  right: 0;
  height: 100px;
  top: -1px;
  z-index: 20;
  background-color: white;
  border-bottom: 1px solid #0000cc; }

.navCircle {
  display: none;
  height: 10vw;
  width: 10vw;
  background-color: white;
  position: fixed;
  top: -10vw;
  right: -10vw;
  border-radius: 50%; }

.topBar {
  position: fixed;
  left: 10%;
  right: 10%;
  top: 15px;
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.mainNav {
  display: flex;
  justify-content: flex-end; }
  .mainNav ul {
    display: flex; }
  .mainNav li {
    text-align: center;
    font-size: 0.7rem;
    margin-left: 2vw; }
    .mainNav li .contactLink,
    .mainNav li .logIn {
      display: block;
      text-align: center;
      font-size: 0.75rem;
      padding: 15px 20px;
      border-radius: 30px; }
    .mainNav li .contactLink:hover .animatedBorder::after {
      animation: borderExpand 0.4s ease-out forwards; }
    .mainNav li .animatedBorder {
      display: block;
      position: relative; }
      .mainNav li .animatedBorder::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 0;
        height: 1px;
        background-color: #0000cc; }

@keyframes borderExpand {
  from {
    width: 0%; }
  to {
    width: 100%; } }
    .mainNav li .logIn {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      background-color: #0000cc;
      color: white; }
      .mainNav li .logIn:hover {
        background-color: #ff99cc;
        color: #0000cc; }
    .mainNav li .movingText1 {
      display: inline-block;
      white-space: nowrap; }

.miniNav {
  display: none; }

.logoBoxContainer, .hamburger {
  position: relative;
  z-index: 10; }

.hamburger {
  cursor: pointer;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  width: auto;
  border-radius: 0; }
  .hamburger .topBun,
  .hamburger .bottomBun {
    height: 3px;
    width: 25px;
    background-color: #0000cc; }
  .hamburger .topBun {
    margin-bottom: 8px; }
  .hamburger:hover {
    background-color: rgba(0, 0, 0, 0); }

.hamburgerLinks {
  display: none;
  opacity: 0;
  text-align: center;
  position: absolute;
  top: 40vh;
  left: 0;
  right: 0; }
  .hamburgerLinks a {
    font-size: 2rem;
    text-transform: none; }
  .hamburgerLinks li {
    margin-bottom: 5vh; }

.hamburgerContactLink:hover,
.hamburgerLogIn:hover {
  color: #ff99cc; }

.fadeIn {
  animation: fadeIn 0.75s linear forwards; }

.fadeOut {
  animation: fadeOut 0.375s linear forwards; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.open .topBun {
  animation: topBunOpen 0.5s linear forwards; }

.open .bottomBun {
  animation: bottomBunOpen 0.5s linear forwards; }

.close .topBun {
  animation: topBunClose 0.5s linear forwards; }

.close .bottomBun {
  animation: bottomBunClose 0.5s linear forwards; }

.expand {
  animation: expand 0.3s linear forwards; }

.contract {
  animation: contract 0.75s linear forwards; }

@keyframes expand {
  from {
    transform: scale(1); }
  to {
    transform: scale(60); } }

@keyframes contract {
  0% {
    transform: scale(60); }
  50% {
    transform: scale(60); }
  100% {
    transform: scale(1); } }

@keyframes topBunOpen {
  0% {
    transform: translateY(0px) rotate(0deg); }
  50% {
    transform: translateY(5.5px) rotate(0deg); }
  100% {
    transform: translateY(5.5px) rotate(45deg); } }

@keyframes bottomBunOpen {
  0% {
    transform: translateY(0px) rotate(0deg); }
  50% {
    transform: translateY(-5.5px) rotate(0deg); }
  100% {
    transform: translateY(-5.5px) rotate(-45deg); } }

@keyframes topBunClose {
  0% {
    transform: translateY(5.5px) rotate(45deg); }
  50% {
    transform: translateY(5.5px) rotate(0deg); }
  100% {
    transform: translateY(0px) rotate(0deg); } }

@keyframes bottomBunClose {
  0% {
    transform: translateY(-5.5px) rotate(-45deg); }
  50% {
    transform: translateY(-5.5px) rotate(0deg); }
  to {
    transform: translateY(0px) rotate(0deg); } }

@media (max-width: 500px) {
  .sideNav {
    display: none; }
  .topBarContainer {
    height: 80px; }
  .topBar {
    top: 3vh;
    left: 8%;
    right: 8%; }
  .mainNav {
    display: none; }
  .miniNav,
  .navCircle {
    display: block; } }

.floatingPoints {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

.star1,
.star2,
.star3,
.circle1,
.circle2,
.circle3,
.circle4,
.circle5,
.circle6,
.circle7 {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }

.star1 {
  position: absolute;
  bottom: 0;
  left: 16.5%;
  animation: float-s1 12s ease-in -2s infinite;
  z-index: -20;
  /* Controlls the size of the stars. */
  font-size: 25px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;
  border-right: 0.28em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.28em solid transparent; }
  .star1:before, .star1:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0.6em;
    left: -1em;
    border-right: 1em solid transparent;
    border-bottom: 0.7em solid #fc0;
    border-left: 1em solid transparent;
    transform: rotate(-35deg); }
  .star1:after {
    transform: rotate(35deg); }

.star2 {
  position: absolute;
  bottom: 0;
  right: 21%;
  animation: float-s2 13s ease-in -3s infinite;
  z-index: -20;
  /* Controlls the size of the stars. */
  font-size: 30px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;
  border-right: 0.27em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.27em solid transparent; }
  .star2:before, .star2:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0.6em;
    left: -1em;
    border-right: 1em solid transparent;
    border-bottom: 0.7em solid #fc0;
    border-left: 1em solid transparent;
    transform: rotate(-35deg); }
  .star2:after {
    transform: rotate(35deg); }

.star3 {
  position: absolute;
  bottom: 0;
  right: 6%;
  animation: float-s3 15s ease-in -5s infinite;
  z-index: -20;
  /* Controlls the size of the stars. */
  font-size: 25px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;
  border-right: 0.27em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.27em solid transparent; }
  .star3:before, .star3:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0.6em;
    left: -1em;
    border-right: 1em solid transparent;
    border-bottom: 0.7em solid #fc0;
    border-left: 1em solid transparent;
    transform: rotate(-35deg); }
  .star3:after {
    transform: rotate(35deg); }

.circle1 {
  position: absolute;
  bottom: 0;
  right: 20%;
  animation: float-c1 13s ease-in -1s infinite;
  z-index: -20;
  text-align: center;
  height: 10px;
  width: 22px; }
  .circle1:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: #6666ff;
    transform: skew(0deg, 6deg); }
  .circle1:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: #6666ff;
    transform: skew(0deg, -6deg); }

.circle2 {
  position: absolute;
  bottom: 0;
  right: 15%;
  animation: float-c2 10s ease-in -3s infinite;
  z-index: -20;
  text-align: center;
  height: 10px;
  width: 22px; }
  .circle2:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: magenta;
    transform: skew(0deg, 6deg); }
  .circle2:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: magenta;
    transform: skew(0deg, -6deg); }

.circle3 {
  position: absolute;
  bottom: -150px;
  right: 35%;
  animation: float-c3 17s ease-in -5s infinite;
  z-index: -20;
  text-align: center;
  height: 10px;
  width: 22px; }
  .circle3:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: #99ffcc;
    transform: skew(0deg, 6deg); }
  .circle3:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: #99ffcc;
    transform: skew(0deg, -6deg); }

.circle4 {
  position: absolute;
  bottom: 0;
  left: 40%;
  animation: float-c4 11s ease-in -4s infinite;
  z-index: -20;
  text-align: center;
  height: 10px;
  width: 22px; }
  .circle4:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: red;
    transform: skew(0deg, 6deg); }
  .circle4:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: red;
    transform: skew(0deg, -6deg); }

.circle5 {
  position: absolute;
  bottom: 0;
  left: 15%;
  animation: float-c5 14s ease-in -9s infinite;
  z-index: -20;
  text-align: center;
  height: 10px;
  width: 22px; }
  .circle5:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: #6666ff;
    transform: skew(0deg, 6deg); }
  .circle5:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: #6666ff;
    transform: skew(0deg, -6deg); }

.circle6 {
  position: absolute;
  bottom: 0;
  left: 30%;
  animation: float-c6 13s ease-in -2s infinite;
  z-index: -20;
  text-align: center;
  height: 10px;
  width: 22px; }
  .circle6:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: #99ffcc;
    transform: skew(0deg, 6deg); }
  .circle6:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: #99ffcc;
    transform: skew(0deg, -6deg); }

.circle7 {
  position: absolute;
  bottom: 0;
  left: 20%;
  animation: float-c7 15s ease-in -1s infinite;
  z-index: -20;
  text-align: center;
  height: 10px;
  width: 22px; }
  .circle7:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: magenta;
    transform: skew(0deg, 6deg); }
  .circle7:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: magenta;
    transform: skew(0deg, -6deg); }

@keyframes float-s1 {
  0% {
    transform: translateY(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: translateY(-120vh) translateX(-20vh) rotate(-180deg);
    opacity: 1; } }

@keyframes float-s2 {
  0% {
    transform: translateY(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: translateY(-90vh) rotate(180deg);
    opacity: 1; } }

@keyframes float-s3 {
  0% {
    transform: translateY(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: translateY(-120vh) translateX(10vh) rotate(360deg);
    opacity: 1; } }

@keyframes float-c1 {
  0% {
    transform: translateY(0) rotate(90deg);
    opacity: 0; }
  10% {
    opacity: 0.8; }
  100% {
    transform: translateY(-120vh) translatex(10vh) rotate(270deg);
    opacity: 0.8; } }

@keyframes float-c2 {
  0% {
    transform: translateY(0) rotate(45deg);
    opacity: 0; }
  10% {
    opacity: 0.8; }
  100% {
    transform: translateY(-120vh) translateX(25vh) rotate(360deg);
    opacity: 0.8; } }

@keyframes float-c3 {
  0% {
    transform: translateY(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: translateY(-120vh) translateX(20vh) rotate(360deg);
    opacity: 1; } }

@keyframes float-c4 {
  0% {
    transform: translateY(0) rotate(90deg);
    opacity: 0; }
  10% {
    opacity: 0.7; }
  100% {
    transform: translateY(-120vh) translateX(-10vh) rotate(-450deg);
    opacity: 0.7; } }

@keyframes float-c5 {
  0% {
    transform: translateY(0) rotate(-40deg);
    opacity: 0; }
  10% {
    opacity: 0.8; }
  100% {
    transform: translateY(-120vh) translateX(-20vh) rotate(-360deg);
    opacity: 0.8; } }

@keyframes float-c6 {
  0% {
    transform: translateY(0) rotate(-45deg);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: translateY(-120vh) translateX(-20vh) rotate(-460deg);
    opacity: 1; } }

@keyframes float-c7 {
  0% {
    transform: translateY(0) rotate(15deg);
    opacity: 0; }
  10% {
    opacity: 0.7; }
  100% {
    transform: translateY(-120vh) translateX(-20vh) rotate(-500deg);
    opacity: 0.7; } }

.sectionOneBg {
  position: relative; }

.landingPage {
  position: relative; }

.lineBreak {
  display: block; }

.mission,
.sectionTwo,
.sectionThree,
.sectionFourUpper {
  padding: 12vh 0 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative; }
  .mission .cursorDownContainer,
  .sectionTwo .cursorDownContainer,
  .sectionThree .cursorDownContainer,
  .sectionFourUpper .cursorDownContainer {
    position: static;
    margin-top: 10vh; }

.sectionOne {
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center; }
  .sectionOne .landingPageTextSub {
    position: relative;
    margin: 5vh 0 0 0; }
  .sectionOne a {
    display: flex;
    justify-content: center; }
  .sectionOne button {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    justify-content: space-evenly; }
    .sectionOne button:hover {
      background-color: #ff99cc; }
    .sectionOne button:hover .fa-arrow-up {
      animation: arrowPointBottom 0.75s ease-in-out infinite forwards; }
  .sectionOne .sectionOneLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100%); }
    .sectionOne .sectionOneLeft .landingPageText {
      position: relative;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: left; }

@keyframes border7ExpandLarge {
  0% {
    opacity: 0;
    right: 100%; }
  to {
    opacity: 1;
    right: 200px; } }
    .sectionOne .sectionOneLeft p {
      text-align: center; }

.headlineBox {
  width: 350px; }

.mission {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .mission .missionDescription {
    display: flex;
    flex-direction: column;
    text-align: left; }
    .mission .missionDescription .missionTitle {
      margin-bottom: 5vh; }
    .mission .missionDescription .missionText {
      margin-bottom: 10vh; }
      .mission .missionDescription .missionText .reachOut {
        text-transform: none;
        font-size: 1.1rem;
        font-weight: normal;
        text-decoration: underline; }
        .mission .missionDescription .missionText .reachOut:hover {
          cursor: pointer; }
    .mission .missionDescription h1 {
      font-size: 2rem;
      font-weight: normal;
      text-align: left;
      font-family: 'IBM Plex Sans'; }
    .mission .missionDescription p {
      font-size: 1.1rem;
      line-height: 1.5;
      margin-bottom: 5vh; }
    .mission .missionDescription em {
      font-weight: bold; }

.sectionTwo .isaDescription {
  display: flex;
  text-align: left; }
  .sectionTwo .isaDescription p {
    font-size: 1.1rem; }
  .sectionTwo .isaDescription em {
    font-weight: bold; }

.sectionTwo .isaDescriptionLower {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 350px); }
  .sectionTwo .isaDescriptionLower .questionsS2 {
    width: 100%;
    margin-top: 6vh; }
    .sectionTwo .isaDescriptionLower .questionsS2 a {
      text-transform: none;
      text-decoration: underline;
      font-size: 0.9rem;
      font-weight: bold; }

.sectionTwo .light {
  font-weight: 500; }

.sectionTwo ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  width: 100%; }

.sectionTwo figure {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: #0000cc;
  border: 2px solid #0000cc;
  padding: 5%;
  border-radius: 25px;
  box-shadow: 5px 5px 0px 0px #0000cc; }
  .sectionTwo figure .emojiContainer {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%; }
  .sectionTwo figure .emoji {
    font-size: 1.5rem; }
  .sectionTwo figure p {
    text-align: left; }

.sectionTwo figure:nth-child(3),
.sectionTwo figure:last-child {
  padding-bottom: 0; }

.sectionTwo li {
  width: calc(50% - 10px);
  display: flex;
  margin-top: 20px; }

.sectionTwo figcaption .subFig {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-left: 10px; }
  .sectionTwo figcaption .subFig p {
    width: 100%;
    line-height: 1.5;
    color: #0000cc;
    font-family: "avenir", serif;
    font-size: 1rem;
    font-weight: 800; }
    .sectionTwo figcaption .subFig p:hover {
      color: #0000cc; }
  .sectionTwo figcaption .subFig p.light {
    font-weight: 500;
    margin-top: 15px; }

.sectionTwo h3 {
  margin-bottom: 30px; }

.sectionTwo .institutions {
  margin: 0;
  text-align: center; }

.sectionThree .sectionThreeLower {
  display: flex; }

.sectionThree ul.contentBox {
  width: calc(100% - 350px); }

.sectionThree li.howStep {
  display: flex;
  border: 1px solid #0000cc;
  margin-bottom: 20px;
  padding: 30px;
  padding-bottom: 40px;
  border-radius: 25px; }
  .sectionThree li.howStep:hover span {
    color: #0000cc; }

.sectionThree li:last-child {
  margin-bottom: 0; }

.sectionThree .stepLeft {
  padding-right: 35px;
  font-size: 4rem; }
  .sectionThree .stepLeft span {
    display: block;
    font-weight: 900;
    line-height: 0.97;
    color: #0000cc;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #0000cc; }

.sectionThree .stepRight {
  line-height: 1.6;
  font-size: 1rem; }
  .sectionThree .stepRight h4 {
    font-weight: 800;
    margin: 0; }
    .sectionThree .stepRight h4 span {
      font-weight: normal; }
  .sectionThree .stepRight p {
    margin-top: 20px;
    line-height: 1.5; }
  .sectionThree .stepRight ul,
  .sectionThree .stepRight .subText {
    font-weight: 500; }
    .sectionThree .stepRight ul a,
    .sectionThree .stepRight .subText a {
      font-size: 0.85rem;
      font-weight: 600;
      text-transform: none;
      text-decoration: underline; }
  .sectionThree .stepRight ul {
    margin: 20px 0 0 20px; }
  .sectionThree .stepRight .helpWith {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px; }
    .sectionThree .stepRight .helpWith li {
      width: calc(50% - 15px); }
  .sectionThree .stepRight p {
    font-size: 0.9rem; }
  .sectionThree .stepRight li {
    font-size: 0.9rem;
    line-height: 1.5;
    list-style-type: circle;
    list-style-position: outside;
    margin: 10px 0 0 15px;
    padding-left: 10px; }

.sectionFour {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative; }
  .sectionFour .sectionFourContent {
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    align-items: center; }
  .sectionFour h2 {
    line-height: 1.1; }
    .sectionFour h2 span {
      line-height: 1.6; }
  .sectionFour .headlineBox,
  .sectionFour .stuBenefitsList,
  .sectionFour .requestIsa {
    position: relative; }
  .sectionFour ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%; }
  .sectionFour li {
    width: 100%;
    margin-bottom: 6vh;
    position: relative; }
    .sectionFour li:hover .stuImg {
      opacity: 0; }
    .sectionFour li:hover h3 {
      -webkit-text-stroke-width: 0px;
      color: #0000cc; }
  .sectionFour .gifSelector {
    position: absolute;
    left: -100vw;
    right: -100vw;
    top: 0;
    bottom: 0; }
    .sectionFour .gifSelector:hover + .stuGif {
      display: none; }
  .sectionFour li:last-child {
    margin-bottom: 0; }
  .sectionFour figure {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .sectionFour figure.evenFigure {
    flex-direction: row-reverse; }
  .sectionFour h3 {
    font-size: 2rem;
    font-weight: 900;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #0000cc;
    color: rgba(0, 0, 0, 0); }
  .sectionFour .stuImgBox {
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    width: 320px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .sectionFour figcaption {
    width: 45%; }
  .sectionFour .stuImg {
    height: 100%;
    width: auto; }
  .sectionFour .stuGif {
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    z-index: -1; }
  .sectionFour button {
    margin-top: 8vh;
    width: 315px;
    display: flex;
    justify-content: space-evenly; }
    .sectionFour button:hover .fa-arrow-up {
      animation: arrowPointBottom 0.75s ease-in-out infinite forwards; }
    .sectionFour button span.lower {
      text-transform: lowercase;
      white-space: normal; }

.contactBg {
  position: relative; }

.contactUs {
  position: relative;
  text-align: center; }

.contact {
  padding: 12vh 0 calc(12vh + 10px) 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative; }

.formAsideBox {
  display: flex;
  justify-content: space-between;
  margin-top: 8vh; }

.formBox {
  position: relative;
  width: calc(100% - 330px);
  display: flex; }

form {
  width: 100%;
  padding-right: 12%; }
  form label {
    font-size: 0.6rem; }
  form fieldset {
    width: 100%;
    margin: 10px 0 15px 0; }
  form .radioOptionsUpper {
    display: flex;
    justify-content: space-between; }
    form .radioOptionsUpper p {
      font-size: 0.8rem; }
  form .radioBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center; }
    form .radioBox label {
      font-size: 0.7rem;
      display: flex;
      align-items: center; }
  form input[type="radio"] {
    opacity: 0; }
  form input[type="radio"] + label .outerRadio {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #0000cc;
    border-radius: 50%;
    position: relative;
    margin-left: 10px; }
  form input[type="radio"]:checked + label .innerRadio {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #0000cc;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 3px);
    right: calc(50% - 3px); }
  form .nameBox,
  form .emailBox,
  form .schoolBox,
  form .programBox {
    display: flex;
    flex-direction: column;
    width: 48%; }
  form .upperForm {
    display: flex;
    flex-direction: column; }
  form .formName,
  form .formEmail,
  form .formSchool,
  form .formProgram,
  form .formLength,
  form .formCost,
  form .formStudents,
  form .formComment {
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 0;
    font-size: 0.8rem;
    font-family: "IBM Plex Sans", serif;
    border-radius: 0; }
    form .formName::placeholder,
    form .formEmail::placeholder,
    form .formSchool::placeholder,
    form .formProgram::placeholder,
    form .formLength::placeholder,
    form .formCost::placeholder,
    form .formStudents::placeholder,
    form .formComment::placeholder {
      font-size: 0.8rem;
      font-family: "IBM Plex Sans", serif;
      font-weight: lighter; }
    form .formName:focus,
    form .formEmail:focus,
    form .formSchool:focus,
    form .formProgram:focus,
    form .formLength:focus,
    form .formCost:focus,
    form .formStudents:focus,
    form .formComment:focus {
      background-color: rgba(255, 153, 204, 0.6);
      outline: none; }
  form .formName,
  form .formEmail,
  form .formSchool,
  form .formProgram,
  form .formCost,
  form .formLength,
  form .formStudents {
    margin: 0 0 20px 0;
    border: none;
    border-bottom: 1px solid #0000cc; }
  form .formComment {
    margin: 10px 0 20px 0;
    padding: 10px; }
  form .nameEmail,
  form .schoolProgram {
    display: flex;
    justify-content: space-between; }
  form .formName,
  form .formEmail,
  form .formProgram,
  form .formSchool {
    width: 100%; }
  form textarea {
    width: 100%;
    min-height: 200px;
    border: 1px solid #0000cc;
    resize: none; }
  form .submitBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 120px; }
    form .submitBox .fa-arrow-up {
      transform: rotate(45deg); }
    form .submitBox:hover .fa-arrow-up {
      animation: arrowPointTopRight 0.75s ease-out infinite; }

@keyframes arrowPointTopRight {
  from {
    transform: translate(0px, 0px) rotate(45deg); }
  to {
    transform: translate(3px, -3px) rotate(45deg); } }

.asideBox {
  height: auto;
  position: relative; }

aside {
  border: 2px solid #0000cc;
  padding: 1% 6%;
  position: relative;
  background-color: #efeeff;
  height: 100%;
  width: 320px;
  right: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 25px; }
  aside h3 {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: underline; }
    aside h3 span {
      font-weight: lighter; }
  aside h4 {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 8px; }
  aside p {
    text-align: center;
    font-size: 0.7rem;
    font-weight: 500; }
  aside .motto {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center; }
    aside .motto span {
      display: block; }
  aside:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #0000cc;
    z-index: -1;
    top: 4px;
    left: 4px;
    background: #0000cc;
    border-radius: 25px; }

@media (max-width: 1317px) {
  h1 {
    font-size: 3.3rem; }
  p {
    font-size: 1.9rem; }
  h2 {
    font-size: 2.7rem;
    text-align: center; }
  .cursorHover,
  .cursorNormal {
    width: 60px; }
  .mission .cursorDownContainer {
    margin-top: 0; }
  .sectionTwo .isaDescription {
    flex-direction: column;
    align-items: center; }
    .sectionTwo .isaDescription p {
      font-size: 1rem; }
    .sectionTwo .isaDescription .headlineBox {
      width: 100%;
      line-height: 0.9; }
      .sectionTwo .isaDescription .headlineBox span {
        display: inline;
        line-height: 0.9; }
  .sectionTwo li {
    max-width: none; }
  .sectionTwo .isaDescriptionLower {
    margin-top: 6vh;
    width: 100%;
    padding: 0 10%;
    min-width: 670px; }
  .sectionThree .sectionThreeLower {
    flex-direction: column; }
  .sectionThree .headlineBox,
  .sectionThree ul.contentBox {
    width: 100%; }
  .sectionThree ul.contentBox {
    margin-top: 6vh;
    padding: 0 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .sectionThree .stepRight .helpWith .helpWithOne,
  .sectionThree .stepRight .helpWith .helpWithThree {
    width: calc(40% - 15px); }
  .sectionThree .stepRight .helpWith .helpWithTwo,
  .sectionThree .stepRight .helpWith .helpWithFour {
    width: calc(60% - 15px); }
  .sectionFourUpper {
    display: flex;
    flex-direction: column; }
  .sectionFour {
    flex-direction: column; }
    .sectionFour .studentBenefits {
      width: 100%; }
    .sectionFour h2 {
      width: 100%;
      line-height: 1.1; }
      .sectionFour h2 span {
        display: inline;
        line-height: 0.9; }
    .sectionFour .sectionFourContent {
      margin-top: 8vh;
      width: 100%; }
    .sectionFour figure {
      padding: 0 10%; }
    .sectionFour .stuImgBox {
      width: 40%;
      height: 275px; }
    .sectionFour figcaption {
      width: 40%; }
  .formAsideBox {
    flex-direction: column;
    align-items: center; }
  .formBox {
    width: 80%; }
  form {
    width: 100%;
    padding-right: 0; }
    form .submitBox {
      margin-top: 0; }
  .asideBox {
    height: auto;
    margin-top: 8vh; }
  aside {
    width: calc(100% - 8px);
    padding: 4vh 4%;
    right: 0px;
    flex-direction: row;
    flex-wrap: wrap; }
    aside h3 {
      width: 100%; }
    aside .fact1,
    aside .fact2,
    aside .fact3 {
      width: calc(100% / 3);
      margin: 3.6vh 0 3.4vh 0; }
    aside .motto {
      width: 100%; }
      aside .motto span {
        display: inline; } }

@media (max-width: 1150px) {
  @keyframes border7ExpandSmall {
    0% {
      opacity: 0;
      right: 100%; }
    to {
      opacity: 1;
      right: 20%; } }
  .mission .missionDescription .missionText {
    margin-bottom: 5vh; }
  .sectionThree .stepRight li {
    margin-top: 5px; }
  .sectionThree .stepRight .helpWith .helpWithOne,
  .sectionThree .stepRight .helpWith .helpWithThree,
  .sectionThree .stepRight .helpWith .helpWithTwo,
  .sectionThree .stepRight .helpWith .helpWithFour {
    width: calc(100% - 15px); }
  .sectionFour .stuImgBox,
  .sectionFour figcaption {
    width: 45%; } }

@media (max-width: 803px) {
  h1 {
    font-size: 2.5rem; }
  p {
    font-size: 1.4rem; }
  h2 {
    font-size: 2.3rem; }
  .cursorHover,
  .cursorNormal {
    width: 50px; }
  .mission .missionDescription .missionTitle h1 {
    font-size: 1.5rem; }
  .mission .missionDescription .missionText {
    margin-bottom: 0; }
    .mission .missionDescription .missionText p {
      font-size: 0.9rem; }
    .mission .missionDescription .missionText .reachOut {
      font-size: 0.9rem; }
  .sectionTwo .isaDescription p {
    font-size: 0.9rem; }
  .sectionTwo .isaDescription figcaption .subFig p {
    font-size: 0.9rem; }
  .sectionTwo .isaDescriptionLower {
    width: 100%;
    padding: 0;
    min-width: auto; }
    .sectionTwo .isaDescriptionLower .questionsS2 a {
      font-size: 0.8rem; }
      .sectionTwo .isaDescriptionLower .questionsS2 a:visited {
        color: #0000cc; }
  .sectionThree .stepLeft {
    font-size: 3rem;
    padding-right: 30px; }
  .sectionThree ul.contentBox {
    padding: 0; }
  .sectionThree .stepRight h4 {
    font-size: 0.9rem; }
  .sectionThree .stepRight p,
  .sectionThree .stepRight li,
  .sectionThree .stepRight .subText a {
    font-size: 0.8rem; }
  form .radioOptionsUpper {
    flex-wrap: wrap; }
  form p {
    width: 100%; }
  form .radioBox {
    justify-content: center;
    margin-top: 10px;
    width: calc(100% / 3); }
  .asideBox {
    width: calc(80%);
    display: flex;
    justify-content: center; }
  aside {
    max-width: 400px;
    height: 500px;
    padding: 0 4%;
    right: 4px;
    flex-direction: column;
    justify-content: space-evenly; }
    aside .fact1,
    aside .fact2,
    aside .fact3 {
      width: 100%;
      margin: 0; }
    aside .motto span {
      display: block; } }

@media (max-width: 677px) {
  @keyframes float4 {
    0% {
      transform: translateY(0px);
      opacity: 0; }
    20% {
      opacity: 1; }
    100% {
      transform: translateY(-105vh) rotate(60deg);
      opacity: 1; } }
  .sectionOne .landingPageTextSub {
    position: relative;
    margin: 3.7vh 0 0 0; }
  .sectionTwo .isaDescription ul {
    flex-direction: column;
    align-items: center; }
    .sectionTwo .isaDescription ul li {
      width: 320px; }
  .sectionFour figure,
  .sectionFour figure.evenFigure {
    flex-direction: column; }
  .sectionFour .stuImgBox {
    margin-bottom: 5vh; }
  .sectionFour .stuImgBox {
    width: 220px; }
  .sectionFour figcaption {
    width: 80%; }
    .sectionFour figcaption h3 {
      font-size: 1.6rem; }
  .formBox {
    width: 100%; } }

@media (max-width: 500px) {
  h1 {
    font-size: 2.1rem; }
  h2 {
    font-size: 1.9rem; }
  h3 {
    font-size: 1.2rem; }
  p {
    font-size: 1.2rem; }
  .cursorHover,
  .cursorNormal {
    width: 40px; }
  .sectionOneBg .circleTextBox {
    width: 157px;
    bottom: -6vh; }
  .sectionFour ul {
    margin-bottom: 2vh; }
  .sectionFour li {
    width: 100%; }
  .sectionFour figcaption {
    width: 100%; }
  form .nameEmail,
  form .schoolProgram {
    flex-direction: column; }
  form .nameBox,
  form .emailBox,
  form .schoolBox,
  form .programBox {
    width: 100%; }
  .asideBox {
    width: calc(100%); }
  aside {
    width: calc(100% - 10px); } }

@media (max-width: 425px) {
  h1 {
    font-size: 1.5rem; }
    h1 .lineBreak {
      display: block; }
  p {
    font-size: 0.9rem; }
  h2 {
    font-size: 1.5rem; }
  .mission {
    justify-content: flex-start; }
  .sectionTwo .isaDescription p {
    font-size: 0.8rem; }
  .sectionTwo .isaDescription figcaption .subFig p {
    font-size: 0.8rem; }
  .sectionTwo .isaDescription ul {
    flex-direction: column;
    align-items: center; }
    .sectionTwo .isaDescription ul li {
      width: 100%; }
  .sectionThree .stepLeft {
    font-size: 1.5rem;
    padding-top: 5px;
    padding-right: 20px; }
  .sectionThree li.howStep {
    padding: 20px;
    padding-bottom: 25px; }
  .sectionThree .stepRight ul {
    margin-left: 0; } }

@media (max-width: 500px) and (max-height: 850px) {
  .sectionOne {
    height: calc(100vh);
    padding-top: 0vh; }
    .sectionOne .sectionOneLeft {
      justify-content: center; }
  .cursorDownContainer {
    bottom: 8vh; }
  .cursor {
    display: none; } }

@media (max-height: 500px) {
  .cursorDownContainer,
  .cursorHover,
  .cursorNormal {
    display: none; }
  .sectionOne {
    padding-top: 35px; } }

.logInPage {
  height: 70vh;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .logInPage form.logInForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0; }
  .logInPage .upperFormLogIn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px; }
  .logInPage .formUserEmail,
  .logInPage .formPassword {
    width: 48%;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    border-bottom: 1px solid #0000cc;
    border-radius: 0;
    padding: 10px;
    font-size: 2rem;
    opacity: 0.5;
    color: #0000cc; }
    .logInPage .formUserEmail::placeholder,
    .logInPage .formPassword::placeholder {
      color: #0000cc; }
  .logInPage .passBox {
    display: flex;
    align-items: center; }
    .logInPage .passBox a {
      font-size: 0.8rem; }
  .logInPage .lowerFormLogIn {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .logInPage .logInSubmit {
    margin-top: 0; }
  .logInPage .warningBox {
    position: relative; }
  .logInPage .warning {
    position: absolute;
    display: none;
    margin-top: 20px;
    font-size: 0.7rem;
    color: red;
    width: 100%;
    text-align: center; }

@media (max-width: 973px) {
  .upperFormLogIn {
    flex-direction: column; }
  .logInPage .formUserEmail,
  .logInPage .formPassword {
    width: 100%; }
  .logInPage .formUserEmail {
    margin-bottom: 2vh; } }

@media (max-width: 606px) {
  .logInPage .formUserEmail,
  .logInPage .formPassword {
    font-size: 1.6rem; } }

.dashHeader {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .dashHeader h1 {
    font-size: 3rem; }
  .dashHeader button {
    margin: 0; }

.dashboard {
  margin: 0 auto;
  padding: 120px 0 13vh 0; }
  .dashboard p {
    font-size: 0.8rem; }
  .dashboard h2 {
    text-align: left;
    font-size: 2rem; }
  .dashboard .addStudent {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end; }
  .dashboard .addStudentButton {
    padding: 0;
    margin: 0;
    width: auto;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #0000cc; }
    .dashboard .addStudentButton .fa-times {
      font-size: 1.6rem;
      margin-left: 10px; }
    .dashboard .addStudentButton .fa-user-plus {
      font-size: 1.2rem;
      margin-left: 10px; }
    .dashboard .addStudentButton .addStudentLower {
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .dashboard .formListHeader {
    border-bottom: 2px solid #0000cc;
    margin: 10px 0;
    padding: 10px 170px 10px 20px;
    display: flex; }
    .dashboard .formListHeader h4 {
      font-size: 0.8rem;
      flex: 1 0 calc(50% / 4);
      text-align: left; }
    .dashboard .formListHeader h4:last-child {
      flex: 1 0 50%;
      padding-right: 20px; }

.formList {
  font-size: 0.8rem; }
  .formList li {
    border: none;
    border-bottom: 1px solid #0000cc;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    padding: 10px 20px; }
  .formList .studentInfo {
    width: 100%;
    display: flex; }
  .formList .studentContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 150px); }
  .formList .buttonBox {
    width: 150px; }
    .formList .buttonBox .buttonBoxLower {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px; }
    .formList .buttonBox button {
      background-color: rgba(0, 0, 0, 0);
      padding: 0;
      border: none;
      color: #0000cc;
      width: auto;
      margin: 0; }
    .formList .buttonBox .phoneContact {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .formList .buttonBox p {
      text-align: left;
      width: auto; }
  .formList .upperStudent {
    display: flex; }
    .formList .upperStudent p {
      flex: 1 0 calc(50% / 4);
      text-align: left; }
    .formList .upperStudent p:last-child {
      flex: 1 0 50%;
      padding-right: 20px; }

.studentForm form, .editStudentContainer form {
  border: 1px solid #0000cc;
  margin: 20px 0;
  padding: 20px;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.studentForm .mainForm, .editStudentContainer .mainForm {
  display: flex; }

.studentForm .upperForm, .editStudentContainer .upperForm {
  width: calc(70%);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.studentForm .lowerForm, .editStudentContainer .lowerForm {
  width: calc(30% - 20px); }

.studentForm input[type="text"], .editStudentContainer input[type="text"] {
  width: calc(50% - 20px);
  margin: 10px;
  background-color: white;
  border: none;
  border-bottom: 1px solid #0000cc; }

.studentForm textarea, .editStudentContainer textarea {
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
  margin: 10px; }

.studentForm input[type=submit], .editStudentContainer input[type=submit] {
  margin: 10px 10px 0 10px;
  border: none;
  width: 120px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "IBM Plex Sans", serif; }

footer {
  padding-top: 5vh;
  border-top: 1px solid #0000cc; }

.icons {
  font-weight: 500;
  font-size: 1rem;
  color: #0000cc; }

.footerBg {
  background-color: white; }

.upperFooter {
  display: flex;
  justify-content: space-between; }

.logoBox {
  width: 80px; }
  .logoBox:hover {
    animation: grow 0.3s ease-in forwards; }

@keyframes grow {
  from {
    transform: scale(1); }
  to {
    transform: scale(1.1); } }

.leftFooter {
  display: flex;
  width: 100%; }
  .leftFooter .logoBox {
    margin-right: 10px; }
  .leftFooter .footerLinks {
    margin-top: 3vh; }
  .leftFooter ul {
    display: flex;
    flex-wrap: wrap;
    max-width: 400px; }
  .leftFooter li {
    font-size: 0.7rem;
    margin-right: 35px;
    text-decoration: underline;
    font-weight: 700; }
  .leftFooter .footMotto {
    line-height: 1;
    font-size: 1.8rem;
    text-transform: uppercase;
    line-height: 0.7; }
  .leftFooter a {
    text-transform: none; }

.rightFooter {
  display: flex;
  justify-content: flex-end; }
  .rightFooter li {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 20px; }
    .rightFooter li:last-child {
      margin: 0; }
  .rightFooter a:hover i {
    color: #ff99cc; }

.bottomFooter {
  margin-top: 50px;
  padding: 20px 0;
  border-top: 1px solid #0000cc;
  font-size: 0.7rem;
  display: flex;
  justify-content: space-between; }
  .bottomFooter a {
    display: flex; }
  .bottomFooter p {
    white-space: nowrap;
    font-size: 0.7rem;
    font-weight: 300; }
  .bottomFooter i {
    line-height: 1.6;
    margin-right: 5px;
    transform: rotate(0deg); }
  .bottomFooter .return {
    text-decoration: underline; }

@media (max-width: 677px) {
  .leftFooter .footMotto span {
    margin-top: 10px;
    display: block; } }

@media (max-width: 500px) {
  .leftFooter .footMotto {
    font-size: 1.2rem; }
  .leftFooter ul {
    flex-direction: column; }
  .leftFooter li {
    margin-bottom: 15px; } }

.faqContainer {
  padding: 120px 0 13vh 0;
  min-height: 73vh; }

.questions {
  margin-top: 5vh; }
  .questions .questionSub {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .questions .questionSub:focus {
      outline-color: #ff99cc; }
  .questions h2 {
    line-height: 1.3; }
  .questions a {
    text-transform: lowercase;
    text-decoration: underline;
    font-size: 0.7rem; }
  .questions .questionExpand {
    width: calc(100% - 26px); }
  .questions .plusExpand {
    position: relative;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .questions .spinClockwise {
    animation: spinClockwise 0.3s ease-out forwards; }

@keyframes spinClockwise {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(135deg); } }
  .questions .spinCounter {
    animation: spinCounter 0.3s ease-out forwards; }

@keyframes spinCounter {
  from {
    transform: rotate(135deg); }
  to {
    transform: rotate(0deg); } }
  .questions .horizontal,
  .questions .vertical {
    display: block;
    width: 26px;
    height: 2px;
    background-color: #0000cc;
    position: absolute; }
  .questions .vertical {
    transform: rotate(90deg); }
  .questions li {
    padding: 20px 0;
    border-top: 1px solid #0000cc;
    border-radius: 0; }
    .questions li:last-child {
      border-bottom: 1px solid #0000cc; }
    .questions li button {
      color: #0000cc;
      background-color: rgba(0, 0, 0, 0);
      width: 100%;
      border: none;
      margin: 0;
      padding: 0;
      border-radius: 0;
      text-transform: none; }
  .questions .answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out; }
  .questions h2 {
    text-decoration: none;
    text-align: left;
    font-size: 1rem;
    font-weight: 400; }
  .questions p {
    margin: 20px 0;
    line-height: 1.6; }
  .questions p,
  .questions li {
    font-size: 0.7rem; }
  .questions em {
    font-weight: 600; }
  .questions .isaComponents {
    padding-left: 20px; }
    .questions .isaComponents li {
      margin: 15px 0 15px 15px;
      padding: 0 0 0 10px;
      border: none;
      list-style-type: circle;
      line-height: 1.6; }
