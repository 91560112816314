// SECTION ONE - ANIMATIONS

.floatingPoints {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.star1,
.star2,
.star3,
.circle1,
.circle2,
.circle3,
.circle4,
.circle5,
.circle6,
.circle7 {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

//confetti components

.star1 {
  position: absolute;
  bottom: 0;
  left: 16.5%;
  animation: float-s1 12s ease-in -2s infinite;
  z-index: -20;

  /* Controlls the size of the stars. */
  font-size: 25px;

  //star shape
  display: inline-block;
  width: 0;
  height: 0;

  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;

  border-right: 0.28em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.28em solid transparent;

  &:before,
  &:after {
    content: "";

    display: block;
    width: 0;
    height: 0;

    position: absolute;
    top: 0.6em;
    left: -1em;

    border-right: 1em solid transparent;
    border-bottom: 0.7em solid #fc0;
    border-left: 1em solid transparent;

    transform: rotate(-35deg);
  }

  &:after {
    transform: rotate(35deg);
  }
}

.star2 {
  position: absolute;
  bottom: 0;
  right: 21%;
  animation: float-s2 13s ease-in -3s infinite;
  z-index: -20;

  /* Controlls the size of the stars. */
  font-size: 30px;

  //star shape
  display: inline-block;
  width: 0;
  height: 0;

  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;

  border-right: 0.27em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.27em solid transparent;

  &:before,
  &:after {
    content: "";

    display: block;
    width: 0;
    height: 0;

    position: absolute;
    top: 0.6em;
    left: -1em;

    border-right: 1em solid transparent;
    border-bottom: 0.7em solid #fc0;
    border-left: 1em solid transparent;

    transform: rotate(-35deg);
  }

  &:after {
    transform: rotate(35deg);
  }
}

.star3 {
  position: absolute;
  bottom: 0;
  right: 6%;
  animation: float-s3 15s ease-in -5s infinite;
  z-index: -20;

  /* Controlls the size of the stars. */
  font-size: 25px;

  //star shape
  display: inline-block;
  width: 0;
  height: 0;

  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;

  border-right: 0.27em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.27em solid transparent;

  &:before,
  &:after {
    content: "";

    display: block;
    width: 0;
    height: 0;

    position: absolute;
    top: 0.6em;
    left: -1em;

    border-right: 1em solid transparent;
    border-bottom: 0.7em solid #fc0;
    border-left: 1em solid transparent;

    transform: rotate(-35deg);
  }

  &:after {
    transform: rotate(35deg);
  }
}

.circle1 {
  position: absolute;
  bottom: 0;
  right: 20%;
  animation: float-c1 13s ease-in -1s infinite;
  z-index: -20;

  text-align: center;
  height: 10px;
  width: 22px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: $colorFive;
    transform: skew(0deg, 6deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: $colorFive;
    transform: skew(0deg, -6deg);
  }
}

.circle2 {
  position: absolute;
  bottom: 0;
  right: 15%;
  animation: float-c2 10s ease-in -3s infinite;
  z-index: -20;

  text-align: center;
  height: 10px;
  width: 22px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: magenta;
    transform: skew(0deg, 6deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: magenta;
    transform: skew(0deg, -6deg);
  }
}

.circle3 {
  position: absolute;
  bottom: -150px;
  right: 35%;
  animation: float-c3 17s ease-in -5s infinite;
  z-index: -20;

  text-align: center;
  height: 10px;
  width: 22px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: $colorFour;
    transform: skew(0deg, 6deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: $colorFour;
    transform: skew(0deg, -6deg);
  }
}

.circle4 {
  position: absolute;
  bottom: 0;
  left: 40%;
  animation: float-c4 11s ease-in -4s infinite;
  z-index: -20;

  text-align: center;
  height: 10px;
  width: 22px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: red;
    transform: skew(0deg, 6deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: red;
    transform: skew(0deg, -6deg);
  }
}

.circle5 {
  position: absolute;
  bottom: 0;
  left: 15%;
  animation: float-c5 14s ease-in -9s infinite;
  z-index: -20;

  text-align: center;
  height: 10px;
  width: 22px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: $colorFive;
    transform: skew(0deg, 6deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: $colorFive;
    transform: skew(0deg, -6deg);
  }
}

.circle6 {
  position: absolute;
  bottom: 0;
  left: 30%;
  animation: float-c6 13s ease-in -2s infinite;
  z-index: -20;

  text-align: center;
  height: 10px;
  width: 22px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: $colorFour;
    transform: skew(0deg, 6deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: $colorFour;
    transform: skew(0deg, -6deg);
  }
}

.circle7 {
  position: absolute;
  bottom: 0;
  left: 20%;
  animation: float-c7 15s ease-in -1s infinite;
  z-index: -20;

  text-align: center;
  height: 10px;
  width: 22px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: magenta;
    transform: skew(0deg, 6deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: magenta;
    transform: skew(0deg, -6deg);
  }
}

//Animation for confetti

@keyframes float-s1 {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(-120vh) translateX(-20vh) rotate(-180deg);
    opacity: 1;
  }
}

@keyframes float-s2 {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(-90vh) rotate(180deg);
    opacity: 1;
  }
}

@keyframes float-s3 {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(-120vh) translateX(10vh) rotate(360deg);
    opacity: 1;
  }
}

@keyframes float-c1 {
  0% {
    transform: translateY(0) rotate(90deg); // skew(23deg);
    opacity: 0;
  }
  10% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(-120vh) translatex(10vh) rotate(270deg);
    opacity: 0.8;
  }
}

@keyframes float-c2 {
  0% {
    transform: translateY(0) rotate(45deg);
    opacity: 0;
  }
  10% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(-120vh) translateX(25vh) rotate(360deg);
    opacity: 0.8;
  }
}

@keyframes float-c3 {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(-120vh) translateX(20vh) rotate(360deg);
    opacity: 1;
  }
}

@keyframes float-c4 {
  0% {
    transform: translateY(0) rotate(90deg);
    opacity: 0;
  }
  10% {
    opacity: 0.7;
  }
  100% {
    transform: translateY(-120vh) translateX(-10vh) rotate(-450deg);
    opacity: 0.7;
  }
}

@keyframes float-c5 {
  0% {
    transform: translateY(0) rotate(-40deg);
    opacity: 0;
  }
  10% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(-120vh) translateX(-20vh) rotate(-360deg);
    opacity: 0.8;
  }
}

@keyframes float-c6 {
  0% {
    transform: translateY(0) rotate(-45deg);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(-120vh) translateX(-20vh) rotate(-460deg);
    opacity: 1;
  }
}

@keyframes float-c7 {
  0% {
    transform: translateY(0) rotate(15deg);
    opacity: 0;
  }
  10% {
    opacity: 0.7;
  }
  100% {
    transform: translateY(-120vh) translateX(-20vh) rotate(-500deg);
    opacity: 0.7;
  }
}

.sectionOneBg {
  position: relative;
}

.landingPage {
  position: relative;
}

.lineBreak {
  display: block;
}

.mission,
.sectionTwo,
.sectionThree,
.sectionFourUpper {
  padding: 12vh 0 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  .cursorDownContainer {
    position: static;
    margin-top: 10vh;
  }
}
// end ANIMATIONS

// SECTION ONE

.sectionOne {
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;

  .landingPageTextSub {
    position: relative;
    margin: 5vh 0 0 0;
  }

  a {
    display: flex;
    justify-content: center;
  }

  button {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    justify-content: space-evenly;

    &:hover {
      background-color: $colorTwo;
    }

    &:hover .fa-arrow-up {
      animation: arrowPointBottom 0.75s ease-in-out infinite forwards;
    }
  }

  .sectionOneLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100%);

    .landingPageText {
      position: relative;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: left;
    }

    @keyframes border7ExpandLarge {
      0% {
        opacity: 0;
        right: 100%;
      }

      to {
        opacity: 1;
        right: 200px;
      }
    }

    p {
      text-align: center;
    }
  }
}

.headlineBox {
  width: 350px;
}

// MISSION

.mission {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .missionDescription {
    display: flex;
    flex-direction: column;
    text-align: left;

    .missionTitle {
      margin-bottom: 5vh;
    }

    .missionText {
      margin-bottom: 10vh;

      .reachOut {
        text-transform: none;
        font-size: 1.1rem;
        font-weight: normal;
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }

    h1 {
      font-size: 2rem;
      font-weight: normal;
      text-align: left;
      font-family: 'IBM Plex Sans';
    }

    p {
      font-size: 1.1rem;
      line-height: 1.5;
      margin-bottom: 5vh;
    }

    em {
      font-weight: bold;
    }
  }
}

// SECTION TWO SECTION 2 - What's an ISA

.sectionTwo {
  .isaDescription {
    display: flex;
    text-align: left;

    p {
      font-size: 1.1rem;
    }

    em {
      font-weight: bold;
    }
  }

  .isaDescriptionLower {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 350px);

    .questionsS2 {
      width: 100%;
      margin-top: 6vh;

      a {
        text-transform: none;
        text-decoration: underline;
        font-size: 0.9rem;
        font-weight: bold;
      }
    }
  }

  .light {
    font-weight: 500;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  figure {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: $colorOne;
    border: 2px solid $colorOne;
    padding: 5%;
    border-radius: 25px;
    box-shadow: 5px 5px 0px 0px #0000cc;
    // text-align: center;

    .emojiContainer {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
    }

    .emoji {
      font-size: 1.5rem;
    }

    p {
      text-align: left;
    }
  }

  figure:nth-child(3),
  figure:last-child {
    padding-bottom: 0;
  }

  li {
    width: calc(50% - 10px);
    display: flex;
    margin-top: 20px;
    // max-width: 285px;
  }

  figcaption .subFig {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: 10px;

    p {
      width: 100%;
      line-height: 1.5;
      color: $colorOne;
      font-family: "avenir", serif;
      font-size: 1rem;
      font-weight: 800;

      &:hover {
        color: $colorOne;
      }
    }

    p.light {
      font-weight: 500;
      margin-top: 15px;
    }
  }

  h3 {
    margin-bottom: 30px;
  }

  .institutions {
    margin: 0;
    text-align: center;
  }
}

// SECTION THREE SECTION 3 - How it works

.sectionThree {
  .sectionThreeLower {
    display: flex;
  }

  ul.contentBox {
    width: calc(100% - 350px);
  }

  li.howStep {
    display: flex;
    border: 1px solid $colorOne;
    margin-bottom: 20px;
    padding: 30px;
    padding-bottom: 40px;
    border-radius: 25px;

    &:hover span {
      color: $colorOne;
    }
  }

  li:last-child {
    margin-bottom: 0;
  }

  .stepLeft {
    padding-right: 35px;
    font-size: 4rem;

    span {
      display: block;
      font-weight: 900;
      line-height: 0.97;
      color: rgba(0, 0, 204, 1);
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #0000cc;
    }
  }

  .stepRight {
    line-height: 1.6;
    font-size: 1rem;

    h4 {
      font-weight: 800;
      margin: 0;

      span {
        font-weight: normal;
      }
    }

    p {
      margin-top: 20px;
      line-height: 1.5;
    }

    ul,
    .subText {
      font-weight: 500;

      a {
        font-size: 0.85rem;
        font-weight: 600;
        text-transform: none;
        text-decoration: underline;
      }
    }

    ul {
      margin: 20px 0 0 20px;
    }

    .helpWith {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;

      li {
        width: calc(50% - 15px);
      }
    }

    p {
      font-size: 0.9rem;
    }

    li {
      font-size: 0.9rem;
      line-height: 1.5;
      list-style-type: circle;
      list-style-position: outside;
      margin: 10px 0 0 15px;
      padding-left: 10px;
    }
  }
}

// SECTION FOUR SECTION 4 - gifs

.sectionFour {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;

  .sectionFourContent {
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    line-height: 1.1;
    span {
      line-height: 1.6;
    }
  }

  .headlineBox,
  .stuBenefitsList,
  .requestIsa {
    position: relative;
  }

  ul {
    display: flex;
    // display flex : flex box defines direction of list. default direction is vertical. You can also do things like row reverse.
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  li {
    width: 100%;
    margin-bottom: 6vh;
    position: relative;

    &:hover .stuImg {
      opacity: 0;
    }

    &:hover h3 {
      -webkit-text-stroke-width: 0px;
      color: $colorOne;
    }
  }

  .gifSelector {
    position: absolute;
    left: -100vw;
    right: -100vw;
    top: 0;
    bottom: 0;

    &:hover + .stuGif {
      display: none;
    }
  }

  li:last-child {
    margin-bottom: 0;
  }

  figure {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  figure.evenFigure {
    flex-direction: row-reverse;
    //evenFigure we defined as a class and applied to 2nd child, but you can use nth-child, etc
  }

  h3 {
    font-size: 2rem;
    font-weight: 900;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $colorOne;
    color: rgba(0, 0, 0, 0);
  }

  .stuImgBox {
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    width: 320px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  figcaption {
    width: 45%;
    //this width references the % of the width of the parent element.
  }

  .stuImg {
    height: 100%;
    // usually you avoid setting height of image but since they are being wrapped we want to make sure the window is consistent and set % of box in which it sits.
    width: auto;
    // width: auto is the default setting.
  }

  .stuGif {
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    z-index: -1;
    //higher z-index is more front. negative puts it behind
    //z-index has to follow position: relative/absolute, doesn't work on its own. It will also affect all subdivs of a parent div so be careful!
  }

  button {
    margin-top: 8vh;
    width: 315px;
    display: flex;
    justify-content: space-evenly;

    &:hover .fa-arrow-up {
      animation: arrowPointBottom 0.75s ease-in-out infinite forwards;
    }

    span.lower {
      text-transform: lowercase;
      white-space: normal;
    }
  }
}

.contactBg {
  position: relative;
}

.contactUs {
  position: relative;
  text-align: center;
}

.contact {
  padding: 12vh 0 calc(12vh + 10px) 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}

.formAsideBox {
  display: flex;
  justify-content: space-between;
  margin-top: 8vh;
}

.formBox {
  position: relative;
  width: calc(100% - 330px);
  display: flex;
}

form {
  width: 100%;
  padding-right: 12%;

  label {
    font-size: 0.6rem;
  }

  fieldset {
    width: 100%;
    margin: 10px 0 15px 0;
  }

  .radioOptionsUpper {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 0.8rem;
    }
  }

  .radioBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;

    label {
      font-size: 0.7rem;
      display: flex;
      align-items: center;
    }
  }

  input[type="radio"] {
    opacity: 0;
  }

  input[type="radio"] + label .outerRadio {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid $colorOne;
    border-radius: 50%;
    position: relative;
    margin-left: 10px;
  }

  input[type="radio"]:checked + label .innerRadio {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: $colorOne;
    border-radius: 50%;

    position: absolute;
    top: calc(50% - 3px);
    right: calc(50% - 3px);
  }

  .nameBox,
  .emailBox,
  .schoolBox,
  .programBox {
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  .upperForm {
    display: flex;
    flex-direction: column;
  }

  .formName,
  .formEmail,
  .formSchool,
  .formProgram,
  .formLength,
  .formCost,
  .formStudents,
  .formComment {
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 0;
    font-size: 0.8rem;
    font-family: $primaryFont;
    border-radius: 0;

    &::placeholder {
      font-size: 0.8rem;
      font-family: $primaryFont;
      font-weight: lighter;
    }

    &:focus {
      background-color: $colorTwoLight;
      outline: none;
    }
  }

  .formName,
  .formEmail,
  .formSchool,
  .formProgram,
  .formCost,
  .formLength,
  .formStudents {
    margin: 0 0 20px 0;
    border: none;
    border-bottom: 1px solid $colorOne;
  }

  .formComment {
    margin: 10px 0 20px 0;
    padding: 10px;
    //border-radius: 25px;
  }

  .nameEmail,
  .schoolProgram {
    display: flex;
    justify-content: space-between;
  }

  .formName,
  .formEmail,
  .formProgram,
  .formSchool {
    width: 100%;
  }

  textarea {
    width: 100%;
    min-height: 200px;
    border: 1px solid $colorOne;
    resize: none;
  }

  .submitBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 120px;

    .fa-arrow-up {
      transform: rotate(45deg);
    }

    &:hover .fa-arrow-up {
      animation: arrowPointTopRight 0.75s ease-out infinite;
    }
  }
}

@keyframes arrowPointTopRight {
  from {
    transform: translate(0px, 0px) rotate(45deg);
  }
  to {
    transform: translate(3px, -3px) rotate(45deg);
  }
}

.asideBox {
  height: auto;
  position: relative;
}

aside {
  border: 2px solid $colorOne;
  padding: 1% 6%;
  position: relative;
  background-color: $background3;
  height: 100%;
  width: 320px;
  //color: $colorOne;
  right: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  border-radius: 25px;

  h3 {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: underline;

    span {
      font-weight: lighter;
    }
  }

  h4 {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 8px;
  }

  p {
    text-align: center;
    font-size: 0.7rem;
    font-weight: 500;
  }

  .motto {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;

    span {
      display: block;
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid $colorOne;
    z-index: -1;
    top: 4px;
    left: 4px;

    background: $colorOne;
    border-radius: 25px;
  }
}

// RESPONSIVE

@media (max-width: 1317px) {
  // Text size change
  // h2 moves center,
  //"Aside" box moves to bottom

  h1 {
    font-size: 3.3rem;
  }

  p {
    font-size: 1.9rem;
  }

  h2 {
    font-size: 2.7rem;
    text-align: center;
  }

  .cursorHover,
  .cursorNormal {
    width: 60px;
  }

  .mission {
    .cursorDownContainer {
      margin-top: 0;
    }
  }

  .sectionTwo {
    .isaDescription {
      flex-direction: column;
      align-items: center;

      p {
        font-size: 1rem;
      }

      .headlineBox {
        width: 100%;
        line-height: 0.9;

        span {
          display: inline;
          line-height: 0.9;
        }
      }
    }

    li {
      max-width: none;
    }

    .isaDescriptionLower {
      margin-top: 6vh;
      width: 100%;
      padding: 0 10%;
      min-width: 670px;
    }
  }

  .sectionThree {
    .sectionThreeLower {
      flex-direction: column;
    }

    .headlineBox,
    ul.contentBox {
      width: 100%;
    }

    ul.contentBox {
      margin-top: 6vh;
      padding: 0 10%;

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .stepRight .helpWith {
      .helpWithOne,
      .helpWithThree {
        width: calc(40% - 15px);
      }

      .helpWithTwo,
      .helpWithFour {
        width: calc(60% - 15px);
      }
    }
  }

  .sectionFourUpper {
    display: flex;
    flex-direction: column;
  }

  .sectionFour {
    flex-direction: column;

    .studentBenefits {
      width: 100%;
    }

    h2 {
      width: 100%;
      line-height: 1.1;
      span {
        display: inline;
        line-height: 0.9;
      }
    }

    .sectionFourContent {
      margin-top: 8vh;
      width: 100%;
    }

    figure {
      padding: 0 10%;
    }

    .stuImgBox {
      width: 40%;
      height: 275px;
    }

    figcaption {
      width: 40%;
    }
  }

  .formAsideBox {
    flex-direction: column;
    align-items: center;
  }

  .formBox {
    width: 80%;
  }

  form {
    width: 100%;
    padding-right: 0;

    .submitBox {
      margin-top: 0;
    }
  }

  .asideBox {
    height: auto;
    margin-top: 8vh;
  }

  aside {
    width: calc(100% - 8px);
    padding: 4vh 4%;
    right: 0px;

    flex-direction: row;
    flex-wrap: wrap;

    h3 {
      width: 100%;
    }

    .fact1,
    .fact2,
    .fact3 {
      width: calc(100% / 3);
      margin: 3.6vh 0 3.4vh 0;
    }

    .motto {
      width: 100%;

      span {
        display: inline;
      }
    }
  }
}

@media (max-width: 1150px) {
  // Section 3_3 bullets go from 2 columns to 1
  //

  .sectionOne {
    .sectionOneLeft {
      @keyframes border7ExpandSmall {
        0% {
          opacity: 0;
          right: 100%;
        }

        to {
          opacity: 1;
          right: 20%;
        }
      }
    }
  }

  .mission {
    .missionDescription {
      .missionText {
        margin-bottom: 5vh;
      }
    }
  }

  .sectionThree .stepRight {
    li {
      margin-top: 5px;
    }

    .helpWith {
      .helpWithOne,
      .helpWithThree,
      .helpWithTwo,
      .helpWithFour {
        width: calc(100% - 15px);
      }
    }
  }

  .sectionFour {
    .stuImgBox,
    figcaption {
      width: 45%;
    }
  }
}

@media (max-width: 803px) {
  // Text size change
  h1 {
    font-size: 2.5rem;
  }

  p {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 2.3rem;
  }

  .cursorHover,
  .cursorNormal {
    width: 50px;
  }

  .mission {
    .missionDescription {
      .missionTitle {
        h1 {
          font-size: 1.5rem;
        }
      }
      .missionText {
        margin-bottom: 0;
        p {
          font-size: 0.9rem;
        }
        .reachOut {
          font-size: 0.9rem;
        }
      }
    }
  }

  .sectionTwo {
    .isaDescription {
      p {
        font-size: 0.9rem;
      }

      figcaption .subFig p {
        font-size: 0.9rem;
      }
    }

    .isaDescriptionLower {
      width: 100%;
      padding: 0;
      min-width: auto;

      .questionsS2 a {
        font-size: 0.8rem;

        &:visited {
          color: $colorOne;
        }
      }
    }
  }

  .sectionThree {
    .stepLeft {
      font-size: 3rem;
      padding-right: 30px;
    }

    ul.contentBox {
      padding: 0;
    }

    .stepRight {
      h4 {
        font-size: 0.9rem;
      }
      p,
      li,
      .subText a {
        font-size: 0.8rem;
      }
    }
  }

  form {
    .radioOptionsUpper {
      flex-wrap: wrap;
    }

    p {
      width: 100%;
    }

    .radioBox {
      justify-content: center;
      margin-top: 10px;
      width: calc(100% / 3);
    }
  }

  .asideBox {
    width: calc(80%);
    display: flex;
    justify-content: center;
  }

  aside {
    max-width: 400px;
    height: 500px;
    padding: 0 4%;
    right: 4px;

    flex-direction: column;
    justify-content: space-evenly;

    .fact1,
    .fact2,
    .fact3 {
      width: 100%;
      margin: 0;
    }

    .motto span {
      display: block;
    }
  }
}

@media (max-width: 677px) {
  // Section 1 animations shrink
  // Section 4 (program highlights) goes from 2 columns to 1
  //

  @keyframes float4 {
    0% {
      transform: translateY(0px);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    100% {
      transform: translateY(-105vh) rotate(60deg);
      opacity: 1;
    }
  }

  .sectionOne {
    .landingPageTextSub {
      position: relative;
      margin: 3.7vh 0 0 0;
    }
  }

  .sectionTwo .isaDescription ul {
    flex-direction: column;
    align-items: center;

    li {
      width: 320px;
    }
  }

  .sectionFour {
    figure,
    figure.evenFigure {
      flex-direction: column;
    }

    .stuImgBox {
      margin-bottom: 5vh;
    }

    .stuImgBox {
      width: 220px;
    }

    figcaption {
      width: 80%;

      h3 {
        font-size: 1.6rem;
      }
    }
  }

  .formBox {
    width: 100%;
  }
}

@media (max-width: 500px) {
  // Text size change

  h1 {
    font-size: 2.1rem;
  }

  h2 {
    font-size: 1.9rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: 1.2rem;
  }

  .cursorHover,
  .cursorNormal {
    width: 40px;
  }

  .sectionOneBg .circleTextBox {
    width: 157px;
    bottom: -6vh;
  }

  .sectionFour {
    ul {
      margin-bottom: 2vh;
    }

    li {
      width: 100%;
    }

    figcaption {
      width: 100%;
    }
  }

  form {
    .nameEmail,
    .schoolProgram {
      flex-direction: column;
    }

    .nameBox,
    .emailBox,
    .schoolBox,
    .programBox {
      width: 100%;
    }
  }

  .asideBox {
    width: calc(100%);
  }

  aside {
    width: calc(100% - 10px);
  }
}

@media (max-width: 425px) {
  h1 {
    font-size: 1.5rem;
    .lineBreak {
      display: block;
    }
  }

  p {
    font-size: 0.9rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .mission {
    justify-content: flex-start;
  }

  .sectionTwo {
    .isaDescription {
      p {
        font-size: 0.8rem;
      }

      figcaption .subFig p {
        font-size: 0.8rem;
      }
    }

    .isaDescription ul {
      flex-direction: column;
      align-items: center;

      li {
        width: 100%;
      }
    }
  }

  .sectionThree {
    .stepLeft {
      font-size: 1.5rem;
      padding-top: 5px;
      padding-right: 20px;
    }

    li.howStep {
      padding: 20px;
      padding-bottom: 25px;
    }

    .stepRight {
      ul {
        margin-left: 0;
      }
    }
  }
}

// HEIGHT ADJUSTMENTS

@media (max-width: 500px) and (max-height: 850px) {
  .sectionOne {
    height: calc(100vh);
    padding-top: 0vh;

    .sectionOneLeft {
      justify-content: center;
    }
  }

  .cursorDownContainer {
    bottom: 8vh;
  }

  .cursor {
    display: none;
  }
}

@media (max-height: 500px) {
  .cursorDownContainer,
  .cursorHover,
  .cursorNormal {
    display: none;
  }

  .sectionOne {
    padding-top: 35px;
  }
}
