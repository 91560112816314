.dashHeader {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    font-size: 3rem;
  }

  button {
    margin: 0;
  }
}

.dashboard {
  margin: 0 auto;
  padding: 120px 0 13vh 0;

  p {
    font-size: 0.8rem;
  }

  h2 {
    text-align: left;
    font-size: 2rem;
  }

  .addStudent {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .addStudentButton {
    padding: 0;
    margin: 0;
    width: auto;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $colorOne;

    .fa-times {
      font-size: 1.6rem;
      margin-left: 10px;
    }

    .fa-user-plus {
      font-size: 1.2rem;
      margin-left: 10px;
    }

    .addStudentLower {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .formListHeader {
    border-bottom: 2px solid $colorOne;
    margin: 10px 0;
    padding: 10px 170px 10px 20px;
    display: flex;

    h4 {
      font-size: 0.8rem;
      flex: 1 0 calc(50% / 4);
      text-align: left;
    }

    h4:last-child {
      flex: 1 0 50%;
      padding-right: 20px;
    }
  }
}
