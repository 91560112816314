.logInPage {
  height: 70vh;
  padding-top: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form.logInForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 0;
  }

  .upperFormLogIn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .formUserEmail,
  .formPassword {
    width: 48%;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    border-bottom: 1px solid $colorOne;
    border-radius: 0;
    padding: 10px;
    font-size: 2rem;
    opacity: 0.5;

    color: $colorOne;

    &::placeholder {
      color: $colorOne;
    }
  }

  .passBox {
    display: flex;
    align-items: center;

    a {
      font-size: 0.8rem;
    }
  }

  .lowerFormLogIn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .logInSubmit {
    margin-top: 0;
  }

  .warningBox {
    position: relative;
  }

  .warning {
    position: absolute;
    display: none;
    margin-top: 20px;
    font-size: 0.7rem;
    color: red;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 973px) {
  .upperFormLogIn {
    flex-direction: column;
  }

  .logInPage .formUserEmail,
  .logInPage .formPassword {
    width: 100%;
  }

  .logInPage .formUserEmail {
    margin-bottom: 2vh;
  }
}

@media (max-width: 606px) {
  .logInPage .formUserEmail,
  .logInPage .formPassword {
    font-size: 1.6rem;
  }
}
